<template>
  <div class="flex-column myCreateCircle">
    <pageHead class="messageMainView_head" title="创建圈子" />
    <!-- todo   创建圈子通知-->
    <CreateNotice></CreateNotice>
    <div class="create_content">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <div class="van-field_box">
            <van-field
              required
              :formatter="vanFieldFormatter"
              v-model="circleCreateDTO.title"
              maxlength="10"
              placeholder="取个响亮的名字（不超过十个字）"
            />
          </div>
        </van-cell-group>
        <van-cell-group inset>
          <div class="van-field_box2">
            <van-field
              v-model="circleCreateDTO.introduction"
              rows="3"
              autosize
              :formatter="vanFieldFormatter"
              type="textarea"
              maxlength="100"
              placeholder="简单的介绍下自己圈子，让更多同事可以快速了解"
              show-word-limit
            />
          </div>
        </van-cell-group>
        <van-cell-group inset>
          <!--todo  圈子头像-->
          <van-cell
            required
            @click.prevent="handleOpenAvatar"
            class="cell-item"
            title="圈子头像"
            is-link
          >
            <template #title>
              <p class="flex-row-h-center">圈子头像</p>
            </template>
            <template #default>
              <p v-if="!circleCreateDTO.avatar" class="avatar-value">
                请选择10M以内图片
              </p>
              <div v-else class="avatar-bg">
                <img
                  v-if="circleCreateDTO.avatar"
                  :src="circleCreateDTO.avatar"
                  alt=""
                />
              </div>
            </template>
          </van-cell>
          <!--  todo 头像上传方式-->
          <van-action-sheet
            v-model="avatarActionShow"
            :actions="actions"
            cancel-text="取消"
            close-on-click-action
            @select="handleSelect"
          />
          <!--todo  圈子类型-->
          <van-cell
            required
            title="圈子类型"
            @click.prevent="handleOpenType"
            is-link
          >
            <template #title>
              <p class="flex-row-h-center">圈子类型</p>
            </template>
            <template #default>
              <p v-if="!qzTypeVal" class="avatar-value">请选择</p>
              <span v-else class="qzTypeVal">{{ qzTypeVal }}</span>
            </template>
          </van-cell>

          <van-popup v-model="circleTypeShow" position="bottom">
            <van-picker
              title="圈子类型"
              show-toolbar
              close-on-click-action
              :columns="columns"
              @confirm="onConfirm"
              @cancel="onCancel"
            />
          </van-popup>
        </van-cell-group>

        <!-- todo 圈子背景图 -->
        <van-cell-group inset>
          <van-cell
            required
            class="circle-bg"
            title="圈子背景图"
            is-link
            @click.prevent="handleOpenBg"
          >
            <div slot="default" class="flex-center-row circle-bg-item">
              <span class="default" v-if="circleCreateDTO.backgroundName">{{
                  circleCreateDTO.backgroundName
                }}</span>
              <span class="default" v-else>默认</span>
              <span
                class="circle-bg-color"
                :style="{ backgroundColor: circleCreateDTO.background }"
              ></span>
            </div>
          </van-cell>
          <van-popup class="popup-bg" v-model="circleBgShow" position="bottom">
            <van-picker
              title="圈子背景图"
              show-toolbar
              :columns="backgroundList"
              @confirm="handleConfirmBg"
              @cancel="handleCancelBg"
            >
              <div class="flex-row circle-bg-item" slot="option" slot-scope="item">
                <span
                  class="circle-bg-color"
                  :style="{ backgroundColor: item.value }"
                ></span>
                <span class="color" v-if="item.title">{{
                    item.title.substr(0, 5)
                  }}</span>
              </div>
            </van-picker>
          </van-popup>
        </van-cell-group>
        <!-- todo 圈主/圈成员-->
        <van-cell-group>
          <div class="qcy_q_box">
            <p class="qcy_title">圈成员</p>
            <van-grid :border="false" :column-num="5">
              <!-- todo 圈主-->
              <van-grid-item>
                <AvatarFrame style="width: 46px;height: 46px;" :user-info="userDetail"></AvatarFrame>
                <p class="username" style="margin-top: 4px;">{{ userDetail.username }}</p>
              </van-grid-item>
              <!-- todo 普通成员-->
              <van-grid-item v-for="(item, index) in memberList.slice(0,7)" :key="index">
                <van-badge>
                  <AvatarFrame style="width: 46px;height: 46px;" :user-info="item"></AvatarFrame>
                  <p class="username">{{ item.name }}</p>
                  <template #content>
                    <van-icon
                      @click="deleteMember(item.id)"
                      name="cross"
                      style="font-size: 10px;"
                      class="badge-icon"
                    />
                  </template>
                </van-badge>
              </van-grid-item>
              <!--  todo  添加成员-->
              <van-grid-item>
                <div
                  class="flex-center-row"
                  @click="addMember"
                  style="
                    width: 46px;
                    height: 46px;
                    background: rgba(109, 114, 120, 0.12);
                    border-radius: 50%;
                    font-size: 20px;
                  "
                >
                  <van-icon name="plus" color="#6d7278" />
                </div>
              </van-grid-item>
              <!--  todo  删除-->
              <van-grid-item>
                <div
                  class="flex-center-row"
                  @click="handleOpenDel"
                  style="
                    width: 46px;
                    height: 46px;
                    background: rgba(109, 114, 120, 0.12);
                    border-radius: 50%;
                    font-size: 20px;
                  ">
                  <van-icon name="minus" color="#6d7278" />
                </div>
              </van-grid-item>
            </van-grid>
          </div>
        </van-cell-group>
        <van-cell-group v-if="memberList.length > 7" inset>
          <van-cell
            class="van_cell_read"
            :title="'查看全部' + ( memberList.length+ 1) + '位成员'"
            is-link
            @click.prevent="goShowAllMember"
          />
        </van-cell-group>
        <div class="button_cre">
          <van-button
            round
            block
            style="background: #fe5800; color: #fff"
            @click.prevent="onSubmit"
          >
            创建圈子
          </van-button>
        </div>
      </van-form>
    </div>
    <!--todo  查看已选择的成员列表-->
    <van-popup
      round
      v-model="showAllMember"
      position="bottom"
      :style="{ height: '90%' }"
    >
      <MemberList
        v-if="showAllMember"
        @submit="handleSubmitDel"
        @batchAdd="addMember"
        @batchDel="batchDel"
        @delete="deleteMember"
        :join-list="memberList"
        @back="handleBack"
      ></MemberList>
    </van-popup>
    <!--todo  从组织中选择添加成员-->
    <van-popup
      round
      v-model="showAddMember"
      position="bottom"
      :style="{ height: '90%' }"
    >
      <Organization
        v-if="showAddMember"
        @close="handleClose"
        @submit="handleSubmit"
        :have-member-list="[]"
        :select-member-list="[]"
      ></Organization>
    </van-popup>
    <!-- todo   批量删除-->
    <van-popup
      round
      v-model="isShowDelete"
      position="bottom"

      closeable
      close-icon-position="top-right"
      :style="{ height: '90%' }">
      <BatchDel
        v-if="isShowDelete"
        :member-list="memberList"
        @submit="handleSubmitDel"
        @back="handleBackDel"></BatchDel>
    </van-popup>
  </div>
</template>

<script>
import AvatarFrame from '@/components/avatarFrame'
import CreateNotice from "./components/createNotice";
// import MemberList from '../memberList'
import MemberList from "./components/memberList";
import Organization from "@/components/organization";
import pageHead from "@/components/pageHead";
import {selectFile} from "@/utils/selectImg";
import {vanFieldFormatter} from '@/utils/utils'
// import pickerZdy from '@/components/picker';
import BatchDel from './components/batch/batchDel'
import {
  createCircle,
  getListCircleBackground,
  getListCircleType,
} from "@/api/circle";
import {mapGetters} from "vuex";

export default {
  components: {
    pageHead,
    AvatarFrame,
    MemberList,
    Organization,
    CreateNotice,
    BatchDel
    // pickerZdy
  },
  data () {
    return {
      isShowDelete: false,
      memberList: [],
      showAllMember: false, // 成员弹框显示隐藏
      showAddMember: false, // 添加成员弹框
      message: "",
      qzTypeVal: "",
      // 提交表单信息
      circleCreateDTO: {
        title: "",
        introduction: "", // 简介
        avatar: "",
        circleTypeId: "", // 圈子类型id
        background: "", // 圈子背景
        backgroundName: "", // 圈子背景名称
        circleUserIds: [], // 圈子成员id列表
      },
      circleTypeShow: false,
      circleBgShow: false, // 圈子背景图
      avatarActionShow: false,
      actions: [{name: "拍照"}, {name: "相册上传"}],
      listCircleLis: [], // 圈子类型包含id
      columns: [], // 圈子类型
      columnsQzBj: ["杭州", "宁波"],
      // 滚动方法
      cuIdx: 1,
      show: true,
      backgroundList: ["666666", "00ff00"],
    };
  },
  computed: {
    ...mapGetters(['userDetail'])
  },
  watch: {
    memberList: {
      handler () {
        if (this.memberList.length > 0) {
          this.circleCreateDTO.circleUserIds = [];
          this.memberList.forEach((item) => {
            this.circleCreateDTO.circleUserIds.push(item.id);
          });
        }
      },
      deep: true,
    },
  },
  created () {
    this.initData();
  },
  mounted () {
    //获取屏幕宽度(viewport)
    let htmlWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    //获取htmlDom
    let htmlDom = document.getElementsByTagName("html")[0];
    //设置html的font-size
    htmlDom.style.fontSize = htmlWidth / 10 + "px";

    window.addEventListener("resize", () => {
      let htmlWidth =
        document.documentElement.clientWidth || document.body.clientWidth;
      console.log(htmlWidth);
      //获取htmlDom
      let htmlDom = document.getElementsByTagName("html")[0];
      //设置html的font-size
      htmlDom.style.fontSize = htmlWidth / 10 + "px";
    });
  },
  methods: {
    // 过滤输入的数字
    vanFieldFormatter,
    // 关闭删除界面
    handleBackDel () {
      this.isShowDelete = false
    },
    // 打开删除界面
    handleOpenDel () {
      if (this.memberList.length > 0) {
        this.isShowDelete = true
      } else {
        this.$toast({
          message: '请先添加成员！',
          position: 'top'
        })
      }
    },
    initData () {
      this.getListCircleType();
      this.getListCircleBackground();
    },
    deleteMember (id) {
      this.memberList = this.memberList.filter((item) => item.id !== id);
    },
    handleClose () {
      this.showAddMember = false;
    },
    handleSubmit (value) {
      this.showAddMember = false;
      this.memberList = this.memberList.concat(value);
      console.log(this.memberList)
      this.unique()
    },
    // 数组去重
    unique () {
      let idList = []
      let newList = []
      this.memberList.forEach(item => {
        if (idList.indexOf(item.id) === -1) {
          idList.push(item.id)
          newList.push(item)
        }
      })
      console.log(newList)
      this.memberList = newList
    },
    /**
     * 批量删除
     */
    handleSubmitDel (list) {
      list.forEach((item) => {
        this.memberList = this.memberList.filter(
          (memberItem) => memberItem.id !== item.id
        );
      });
      this.isShowDelete = false
    },
    // 添加成员
    addMember () {
      this.showAddMember = true;
    },
    // 添加成员
    batchDel () {
      this.isShowDelete = true;
    },
    handleBack () {
      this.showAllMember = false;
    },
    /**
     * todo 创建圈子
     */
    onSubmit () {
      const params = this.circleCreateDTO;
      if (!params.title) {
        return this.$notify({
          type: "warning",
          message: "请输入圈子名称",
        });
      }
      if (!params.avatar) {
        return this.$notify({
          type: "warning",
          message: "请上传圈子头像",
        });
      }
      if (!params.circleTypeId) {
        return this.$notify({
          type: "warning",
          message: "请选择圈子类型",
        });
      }

      createCircle(this.circleCreateDTO)
        .then((res) => {
          this.$toast({
            message: "创建成功！",
            position: 'top'
          });
          const that = this;
          setTimeout(function () {
            that.$router.replace({
              path: "/app/circle/circleXq",
              query: {
                id: res,
              },
            });
          }, 300);
        })
        .catch((error) => {
          console.log(error.response.data);
          const data = error.response.data;
          this.$notify({
            type: "warning",
            message: data.msg,
          });
        });
    },
    /**
     * todo 获取圈子背景图列表
     */
    async getListCircleBackground () {
      const res = (await getListCircleBackground()) || [];
      if (res.length > 0) {
        this.circleCreateDTO.background = res[0].value;
        this.circleCreateDTO.backgroundName = res[0].title;
        this.backgroundList = res;
      }
    },
    /**
     * todo 获取圈子类型列表
     */
    async getListCircleType () {
      const res = (await getListCircleType()) || [];
      this.listCircleLis = res;
      res.forEach((v) => {
        this.columns.push(v.title);
      });
    },
    // 上传图片方式
    handleOpenAvatar () {
      // this.avatarActionShow = true
      this.handleSelect();
    },
    // 圈子类型
    handleOpenType () {
      this.circleTypeShow = true;
    },
    // 圈子类型确定
    onConfirm (value) {
      this.listCircleLis.forEach((v) => {
        if (v.title === value) {
          this.circleCreateDTO.circleTypeId = v.id;
        }
      });
      this.qzTypeVal = value;
      this.circleTypeShow = false;
    },
    // 取消
    onCancel () {
      this.circleTypeShow = false;
    },
    // 查看全部成员
    goShowAllMember () {
      this.showAllMember = true;
    },
    handleOpenBg () {
      this.circleBgShow = true;
    },
    // 动作面板点击事件 传参
    async handleSelect () {
      let files = {};
      // if (key === 0) {
      //   files = await selectFile(true);
      // } else if (key === 1) {
      //   files = await selectFile(false);
      // }
      files = await selectFile(false);
      const filesRes = (await this.$upload.multipleFilesUpload(files)) || [];
      if (filesRes.length && filesRes.length > 0) {
        const {filename} = filesRes[0];
        console.log(filesRes[0]);
        this.circleCreateDTO.avatar = filename;
      }
    },
    handleConfirmBg (value) {
      console.log(value);
      this.circleCreateDTO.background = value.value;
      this.circleCreateDTO.backgroundName = value.title;
      this.circleBgShow = false;
    },
    handleCancelBg (value) {
      this.circleBgShow = false;
      console.log(value);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .van-badge{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  font-size: 12px;
  padding: 0;
}

::v-deep {
  .van-popup__close-icon {
    z-index: 12;
  }
}

.popup-bg {
  .circle-bg-item {
    //width: 200px;
    justify-content: flex-start;

    .color {
      width: 100px;
    }
  }
}

.cell-item {
  .van-cell__value {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .avatar-value {
    font-size: 14px;
    color: rgba(109, 114, 120, 0.5);
  }

  .avatar-bg {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 32px;
    height: 32px;
    border-radius: 50%;

    > img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }

  .qzTypeVal {
    position: absolute;
    top: 18.5px;
    right: 41px;
    font-size: 14px;
    color: #000000;
  }
}

::v-deep {
  .van-cell-group--inset {
    margin: 0;
    border-radius: 0;
  }

  .van-field__word-limit {
    font-size: 14px;
    color: rgba(109, 114, 120, 0.5);
  }


  .van-field_box {
    input {
      font-size: 16px;
    }

    input::placeholder {
      font-size: 16px;
      color: rgba(109, 114, 120, 0.5);
    }
  }

  .van-field_box2 {
    .van-cell {
      height: 148px;
    }

    input {
      font-size: 14px;
    }

    input::placeholder {
      color: rgba(109, 114, 120, 0.5);
    }
  }

  .van-picker__cancel {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
  }

  .van-picker__title {
    font-size: 16px;
    color: #000000;
  }

  .van-picker__confirm {
    font-size: 16px;
    color: #000000;
  }

  .van-uploader__preview-image {
    width: 46px;
    height: 46px;
    border-radius: 50%;

    img {
      width: 46px;
      height: 46px;
    }
  }

  .van-uploader__upload {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    //  margin-left: 25px;
    .van-uploader__input {
      width: 46px;
      height: 46px;
    }

    .van-icon-photograph::before {
      content: "+";
      top: -4px;
      position: relative;
    }
  }

  .van-uploader__preview-delete {
    border-radius: 50%;
  }

  .van_cell_read {
    span {
      font-size: 12px;
      color: #323233;
    }
  }

  .van-field__control {
    font-family: PingFang SC, PingFang SC-Medium, serif;
    font-weight: 500;
  }

  .van-button::before {
    width: 149px;
    height: 40px;
  }

  .van-field__word-limit {
    position: absolute;
    right: 0;
    bottom: 15px;
  }

  .van-cell {
  }

  .circle-bg .van-cell__value {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .circle-bg-item {
    .default {
      margin-right: 4px;
    }

    .circle-bg-color {
      width: 32px;
      height: 32px;
      display: inline-block;
      background: #f6f6f6;
      border-radius: 50%;
    }

    .color {
      margin-left: 11px;
    }
  }
}

.myCreateCircle {
  height: 100%;
  overflow: hidden;

  .create_content {
    flex: 1;
    //height: 100%;
    overflow: scroll;
  }

  .qzType_title {
    height: 47.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    border-bottom: 1px solid #ddd;
  }

  .qcy_q_box {
    margin-top: 5px;
    padding: 18px 0;

    .qcy_title {
      font-size: 14px;
      padding-left: 15px;
      color: #000000;
      line-height: 14px;
    }

    .username {
      text-align: center;
      font-size: 0.32rem;
      color: #6d7278;
    }

    .uploader_box {
      padding: 18px 23px 0 22px;
    }
  }

  .button_cre {
    height: 83px;
    padding: 19px 20px;
    margin-top: 25px;
    background: #ffffff;
  }

  .qztx_type_box {
    position: relative;

    .tils {
      position: absolute;
      right: 46px;
      top: -43px;

      .default {
        font-size: 14px;
        color: rgba(109, 114, 120, 0.5);
        position: relative;
        top: -10px;
        right: 4px;
      }

      .qzbjt_header {
        width: 32px;
        height: 32px;
        display: inline-block;
        background: #f4f4f4;
        border-radius: 50%;
      }

      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }
  }
}
</style>
