<template>
  <div class="flex-column">
    <div class="flex-row-space-between-hCenter message_tit" @click.prevent="CircleXzShow">
      <div class="flex-row-h-center qz_box">
        <van-icon size="17" name="volume-o" />
        <span class="icon_xz">圈子创建及管理须知</span>
      </div>
      <van-icon size="17" name="arrow" />
    </div>
    <van-dialog
      confirm-button-text="我知道了"
      class="notice-dialog"
      v-model="noticeShow">
      <div class="flex-column">
        <span class="show_til"></span>
        <p class="ad-title">圈子创建须知</p>
        <p class="ad-subtitle">能力愈大，责任愈大</p>
        <div class="ad-introduce">
          <p>1. 无任何违规违法行为的社区用户均有创建圈子的权限。</p>
          <p>2. 创建圈子的圈名需符合国际法律法规且不重名。</p>
          <p>3. 圈主的职责为拉人进圈，对圈子进行关注，创建圈内内容，维护圈内氛围。</p>
          <p>4. 圈主享有圈内管理员的任免权和圈规制定的权利，圈主需尽快制定圈规以及组建圈管（圈子管理）团队。</p>
          <p>5. 圈主需要遵守《社区公约》和《圈子须知》内的相关规定，积极发展圈子。</p>
          <p>6. 任何用户都可以联系官方助手对圈子进行投诉，圈名违规或圈内存在黄反等违规帖，一经官方审核属实，将对圈子进行关闭处理，同时圈主将被罢免，180天内不得再次创建圈子。</p>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
/**
 * 创建须知
 */
export default {
  name: "createNotice",
  data() {
    return {
      noticeShow: true
    }
  },
  methods: {
    // 弹窗圈子须知
    CircleXzShow() {
      this.noticeShow = true
    },
  }
}
</script>

<style lang="scss" scoped>

::v-deep {
  .van-dialog {
    overflow: visible !important;
  }

  .van-dialog__header {
    padding: 0;
  }

  .van-dialog__content {
    justify-content: center;
    text-align: center;
    margin-top: 60px;
  }

  .van-dialog__confirm {
    width: 149px;
    height: 40px;
    margin-top: 20px;
    margin-bottom: 32px;
    background: #fe5800;
    border-radius: 200px;
    font-size: 16px;
    text-align: CENTER;
    color: #ffffff;
    text-shadow: 0 2px 20px 0 rgba(224, 82, 6, 0.2);
  }

  .van-dialog__footer {
    margin: 0 90px;
  }
}

.message_tit {
  height: 40px;
  padding: 0 16px;
  background: rgba(254, 88, 0, 0.08);
  font-size: 14px;
  color: #fe5800;

  .icon_xz {
    margin-left: 8px;
  }
}

.ad-title {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.90);
  margin-top: 12px;
}

.ad-subtitle {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.40);
  margin-top: 6px;
  margin-bottom: 10px;
}

.show_til {
  width: 166px;
  height: 120px;
  display: inline-block;
  background: url('/images/circle/createNotice.png') no-repeat;
  background-size: 100%;
  position: absolute;
  top: -66px;
  left: 76px;
}


.ad-introduce {
  margin: 0 20px;
  height: 186px;
  overflow-y: auto;
  text-align: left;
  background: #fafafa;
  border-radius: 8px;
  padding: 18px 30px;

  p {
    font-size: 14px;
    color: #6d7278;
    line-height: 25px;
    letter-spacing: 0.01px;
  }
}

</style>
