<template>
  <div class="flex-column batchAdd_main">
    <pageHead class="messageMainView_head" :is-show-page-head="true" :special="true" :hide-back="true" @back="handleBack" :title="title" />
    <van-row class="search_box">
      <van-col span="20">
        <circleSearchs
          :is-focus="false"
          :placeholder="placeholder"
          @click="handleClick"
          @input="handleInput"
          :disabled="false"></circleSearchs>
      </van-col>
      <van-col span="2">
        <div class="cancal" @click.prevent="handleBack"><span class="cancal_title">取消</span></div>
      </van-col>
    </van-row>
    <div class="flex-column " style="flex: 1;overflow-y: auto;padding-bottom: 110px;">
      <!--  todo  圈成员-->
      <van-checkbox-group
        v-if="searchList.length > 0 && isSearch"
        @change="handleChange"
        v-model="selectIdList"
        :max="100">
        <van-checkbox
          checked-color="#FE5800"
          class="delete-item"
          v-for="item in searchList"
          :key="item.id"
          :name="item.id">
          <div class="flex-row-h-center">
            <AvatarFrame style="width: 41px;height: 41px;" :user-info="item"></AvatarFrame>
            <span class="name"> {{ item.name }}</span>
          </div>
        </van-checkbox>
      </van-checkbox-group>

      <van-checkbox-group
        v-if="memberList.length > 0 && !isSearch"
        @change="handleChange"
        v-model="selectIdList"
        :max="100">
        <van-checkbox
          checked-color="#FE5800"
          class="delete-item"
          v-for="item in memberList"
          :key="item.id"
          :name="item.id">
          <div class="flex-row-h-center">
            <AvatarFrame style="width: 41px;height: 41px;" :user-info="item"></AvatarFrame>
            <span class="name"> {{ item.name }}</span>
          </div>
        </van-checkbox>
      </van-checkbox-group>
      <!-- 无数据展示 -->
      <template v-if="memberList.length === 0">
        <div class="hotCircle_noData">
          <div class="noData_box">
            <div class="noData_img"></div>
            <span>{{ noDataInfo.title1 || '暂未数据' }}</span>
          </div>
        </div>
      </template>
    </div>

    <footer class="footer">
      <div class="flex-row-space-between delete-list">
        <div class="flex-row-h-center left-list">
          <van-badge class="flex-center-column" style="margin-right: 10px" v-for="item in selectList" :key="item.id">
            <AvatarFrame class="avatarFrame" :user-info="item"></AvatarFrame>
            <span class="name"> {{ item.name }}</span>
            <template #content>
              <van-icon @click="handleDelete(item)" name="cross" class="badge-icon" />
            </template>
          </van-badge>
        </div>
        <div class="flex-center-row delete-button"
          @click="handleSubmit"
          :class="{baColor: selectList.length > 0}">
          <span>{{ conInfo }}</span>
          <span v-if="selectList.length > 0">({{ selectList.length }})</span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import AvatarFrame from '@/components/avatarFrame'
import pageHead from '@/components/pageHead'
import circleSearchs from '@/components/searchBox'

export default {
  name: 'batchAdd',
  components: {
    pageHead,
    circleSearchs,
    AvatarFrame
  },
  props: {
    memberList: {
      type: Array,
      default: () => []
    },
    title: { // 标题
      type: String,
      default: '标题'
    },
    placeholder: {
      type: String,
      default: ''
    },
    navShow: { // 是否显示按企业架构添加
      type: Boolean,
      default: true
    },
    vanSwipeList: { // 人员列表
      type: Array,
      default: () => []
    },
    noDataInfo: { // 没有数据
      type: Object,
      default: () => {
      }
    },
    conInfo: { // 按钮文案
      type: String,
      default: '确认删除'
    },
    btnColor: {
      type: String,
      default: ''
    }
  },
  created() {
    this.vanSwipeCur = this.vanSwipeList
  },
  data() {
    return {
      selectIdList: [],
      selectList: [], // 选择的数据
      vanSwipeIn: [],
      number: '',
      vanSwipeCur: [],
      isSearch: false, // 是否在搜索
      searchList: [] // 搜索结果
    }
  },
  methods: {
    // 提交
    handleSubmit() {
      this.$dialog.confirm({
        title: '删除成员',
        message: '你确定要删除该成员吗？',
        confirmButtonText: '删除成员',
        cancelButtonText: '再考虑下'
      }).then(() => {
        console.log(this.selectIdList)
        this.selectIdList = []
        this.searchList = []
        this.$emit('submit', this.selectList)
        this.selectList = []
      })
    },
    // 删除不需要删除的某一项
    handleDelete(item) {
      console.log(item)
      this.selectList = this.selectList.filter(row => row.id !== item.id)
      this.selectIdList = this.selectIdList.filter(row => row !== item.id)
    },
    // 选项发生变化
    handleChange(value) {
      console.log(value)
      console.log(this.selectIdList)
      this.selectList = []
      this.selectIdList.forEach(idItem => {
        this.selectList = this.selectList.concat(this.memberList.filter(item => item.id === idItem))
      })
      console.log(this.selectList)
    },
    handleBack() {
      this.$emit('back')
    },
    // 输入框
    handleInput(value) {
      if (value) {
        this.isSearch = true
        this.searchList = this.memberList.filter(item => item.name.indexOf(value) !== -1)
        console.log(this.isSearch)
        console.log(this.searchList)
      } else {
        this.searchList = []
        this.isSearch = false
      }
    },
    // 输入框点击
    handleClick(v) {
      this.$emit('handleClick', v)
    },
    // 多选
    vanSwipeClick(item) {
      if (item.isCheck) {
        this.selectList.splice(item, 1)
      } else {
        this.selectList.push({id: item.id, url: item.header, isCheck: true})
      }
      this.$emit('vanSwipeClick', item)
    },
    // 添加
    fileListAdd() {
      this.$emit('fileListAdd')
    },
    vanCancalClick(item) {
      if (item.isCheck) {
        this.selectList.splice(item, 1)
      } else {
        this.selectList.push({id: item.id, url: item.header, isCheck: true})
      }
      this.$emit('vanSwipeClick', item)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .van-badge{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding: 0;
}
.batchAdd_main {
  background: #fff;
  height: 100%;
  overflow: hidden;

  .delete-item {
    padding: 16px 0;
    margin: 0 24px;
    border-bottom: 1px solid #EBEBEB;

    .name {
      margin-left: 10px;
      font-size: 14px;
      color: #000000;
    }
  }

  .member-item {
    border-bottom: 1px solid #f4f4f4;

    .left {
      padding: 16px 0;
      margin-left: 24px;

      .check-box {
        width: 21px;
        height: 21px;
        margin-right: 10px;
        box-sizing: border-box;
      }

      .checkbox_icon {
        box-sizing: border-box;
        border-radius: 50%;
        border: 2px solid #dcdcdc;
      }

      .active_icon {
        background: url('~@/assets/imgs/circle/Slice43@2x.png');
        background-size: 100%;
      }
    }

    .name {
      margin-left: 10px;
      font-size: 14px;
      color: #000000;
    }

    .delete-button {
      height: 100%;
    }
  }

  .van_cell_box {
    ::v-deep .van-cell {
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #f4f4f4;
      font-size: 16px;
      color: #000000;
    }
  }

  .circleList {
    overflow: scroll;
    width: 230px;
    float: left;

    .circleLi {
      display: flex;
    }

    .item_cir:first-child {
      margin-left: 0;
    }

    .item_cir {
      margin-right: 5px;

      .cir_title {
        font-size: 12px;
        color: #333333;
        text-indent: 6px;
        margin-top: 6px;
        letter-spacing: 0;
      }

      .van_image_box {
        position: relative;

        .van_image_lsBox {
          position: absolute;
          right: 0;
          z-index: 88;
          top: -10px;

          .van_image_ls {
            display: inline-block;
            width: 14px;
            height: 14px;
            color: #fff;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.75);
            position: relative;

            &::after {
              content: '-';
              position: absolute;
              left: 2px;
              top: -9px;
            }
          }
        }

        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
      }
    }
  }

  .uploader_box {
    padding: 18px 15px 0 15px;
    overflow: hidden;

    .submitAdd {
      display: inline-block;
      width: 108px;
      height: 38px;
      background: #f6f6f6;
      border-radius: 100px;

      font-size: 13px;
      text-align: CENTER;
      color: #6d7278;
      line-height: 35px;
      float: right;
    }

    .submitAdd2 {
      display: inline-block;
      width: 108px;
      height: 38px;
      background: #FE5800;
      border-radius: 100px;
      font-size: 13px;
      text-align: CENTER;
      color: #fff;
      line-height: 35px;
      float: right;
    }
  }

  .search_box {
    padding-top: 12px;

    ::v-deep .van-search__content {
      height: 41px;
      padding-left: 15px;
    }

    ::v-deep .van-cell {
      line-height: 31px;
    }
  }

  ::v-deep .van-search__content {
    border-radius: 70px;
  }

  .cancal {
    position: relative;
    left: 10px;
    top: 3px;

    .cancal_title {
      font-size: 14px;
      text-align: center;
      color: #000000;
      line-height: 14px;
    }
  }

  .vant_List {
    overflow: scroll;

    ::v-deep {
      .van-swipe-cell__wrapper {
        display: flex;
        border-bottom: 1px solid #f4f4f4;
      }

      .van-card__title {
        padding-left: 0;
      }

      .van-card {
        padding-left: 10px;
        padding: 0 0 0 10px;
      }

      .van-card:not(:first-child) {
        margin-top: 0;
      }

      .van-swipe-cell:first-child {
        margin-top: 0;
      }

      .van-swipe-cell {
        margin-top: 16px;
        padding: 0 15px;
      }
    }


  }

  .hotCircle_noData {
    display: flex;
    height: 450px;
    justify-content: center;
    align-items: center;
    text-align: center;

    .noData_box {
      margin-bottom: 16px;

      .noData_img {
        width: 212px;
        height: 114px;
        background: url('~@/assets/imgs/circle/Slice39@2x.png') no-repeat;
        background-size: cover;
        margin: 0 auto;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    span {
      font-size: 12px;
      color: #6d7278;
    }
  }

  footer {
    width: 100%;
    height: 108px;
    position: fixed;
    background: #fff;
    border-top: 1px solid #f4f4f4;
    bottom: 0;

    .delete-list {

      padding: 0 16px;

      .left-list {
        flex-wrap: nowrap;
        width: 250px;
        overflow-x: auto;
        padding: 16px 0 0;

        ::v-deep{
          .avatarFrame{
            width: 36px;height: 36px;
          }
          .name{
            font-size: 12px;
            color: #000000;
            word-break: keep-all;
          }
        }
      }
    }

    .delete-button {
      width: 108px;
      height: 38px;
      border-radius: 100px;
      font-size: 13px;
      color: #fff;
      margin-top: 16px;
      background-color: #888888;
    }

    .baColor {
      background-color: #f42626;
    }
  }
}
</style>
