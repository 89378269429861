<template>
  <div class="batchDel_main">
    <batComent title="删除圈成员"
      placeholder="群成员"
      conInfo="确认删除"
      btnColor="#F42626"
      @submit="handleSubmit"
      @back="handleBack"
      :member-list="memberList"
      :vanSwipeList="vanSwipeList"
      :noDataInfo="noDataInfo"
    ></batComent>
  </div>
</template>

<script>
import batComent from './batComent.vue'

export default {
  name: 'batchDel',
  components: {
    batComent,
  },
  props:{
    memberList: {
      type: Array,
      default: ()=>[]
    },
  },
  data() {
    return {
      vanSwipeList: [],
      noDataInfo: {
        title1: '暂无相关人员'
      }
    }
  },
  methods: {
    // 提交
    handleSubmit(list) {
      this.$emit('submit', list)
    },
    handleBack() {
      this.$emit('back')
    },
  }
}
</script>

<style lang="scss" scoped>
.batchDel_main {
  height: 100%;
  overflow: hidden;
}
</style>
