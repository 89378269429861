<template>
  <div class="myCreateCircleAllPe">
    <pageHead :is-show-page-head="true" class="messageMainView_head" :special="true" @back="handleBackMember"
      title="全部成员" />
    <van-row class="flex-row-h-center">
      <van-col span="14">
        <searchBox :is-focus="false" @focus="false" @input="handleInput" :placeholder='placeholder'
          @click.native="toSearch" class="mainView_search" />
      </van-col>
      <van-col span="10" class="batch-opera">
        <span @click.prevent="batchAdd">批量添加</span>
        <span @click.prevent="batchDel" class="delete">批量删除</span>
      </van-col>
    </van-row>

    <!-- 列表-搜索的结果 -->
    <div class="vant_List" v-if="isSearch">
      <van-swipe-cell class="member-item" v-for="(item,index) in searchList" :key="item.id">
        <div class="flex-row-h-center left">
          <AvatarFrame class="user-info-avatar" :user-info="item"></AvatarFrame>
          <span class="name">{{ item.name }}</span>
        </div>

        <template #right>
          <van-button square class="delete-button" @click="vanSwipeDel(index)" type="danger" text="删除" />
        </template>
      </van-swipe-cell>
    </div>

    <!-- 列表-未搜索 -->
    <div class="vant_List" v-else>
      <van-swipe-cell class="member-item" v-for="item in joinList" :key="item.id">
        <div class="flex-row-h-center left">
          <AvatarFrame class="user-info-avatar" :user-info="item"></AvatarFrame>
          <span class="name">{{ item.name }}</span>
        </div>

        <template #right>
          <van-button square class="delete-button" @click="vanSwipeDel(item.id)" type="danger" text="删除" />
        </template>
      </van-swipe-cell>
    </div>

    <van-empty v-if="joinList.length === 0" description="暂无成员" />

    <div v-if="isShowAdd">
      <van-popup
        v-model="isShowAdd"
        position="bottom"
        :style="{ height: '100%' }">
        <BatchAdd @back="handleBack"></BatchAdd>
      </van-popup>
    </div>

    <van-popup
      v-model="isShowDelete"
      position="bottom"
      :style="{ height: '100%' }">
      <BatchDel :member-list="joinList" @submit="handleSubmitDel" @back="handleBackDel"></BatchDel>
    </van-popup>
  </div>
</template>

<script>
import AvatarFrame from '@/components/avatarFrame'
import pageHead from '@/components/pageHead'
import searchBox from '@/components/searchBox'
import BatchAdd from './batch/batchAdd'
import BatchDel from './batch/batchDel'

export default {
  name: 'myCreateCircleAllPe', // 全部成员
  components: {
    pageHead,
    searchBox,
    BatchAdd,
    BatchDel,
    AvatarFrame
  },
  props: {
    joinList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isSearch: false, // 是否搜索
      searchList: [],
      isShowAdd: false,
      isShowDelete: false,
      placeholder: '',
    }
  },
  methods: {
    // 输入框数据变化
    handleInput (value) {
      if (value) {
        this.isSearch = true
        this.searchList = this.joinList.filter(item => item.name.indexOf(value) !== -1)
        console.log(this.joinList)
        console.log(this.searchList)
      } else {
        this.searchList = []
        this.isSearch = false
      }
    },
    // 跳转搜索页面
    toSearch () {
      this.$emit('search', this.joinList)
      console.log(11111)
      // this.$router.push({
      //   name: "myCreateCircleSearch",
      // });
    },
    handleBackMember () {
      this.$emit('back')
    },
    handleBack () {
      this.isShowAdd = false
    },
    handleSubmitDel (list) {
      this.isShowDelete = false
      this.$emit('submit', list)
    },
    handleBackDel () {
      this.isShowDelete = false
    },
    // 跳转添加
    batchAdd () {
      this.$emit('batchAdd')
    },
    // 跳转删除
    batchDel () {
      this.$emit('batchDel')
      // if (this.joinList.length > 0) {
      //   this.isShowDelete = true
      // } else {
      //   this.$toast('请添加成员')
      // }
    },
    // 删除
    vanSwipeDel (id) {
      this.$dialog.confirm({
        title: '对话框标题',
        message: '你确定要删除该成员吗？',
        confirmButtonText: '删除成员',
        cancelButtonText: '再考虑下'
      }).then(() => {
        this.$emit('delete', id)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.myCreateCircleAllPe {
  background: #fff;
  height: 610px;

  .batch-opera {
    .delete {
      margin-left: 20px;
    }
  }

  ::v-deep .van-search__content {
    border-radius: 20px !important;
  }

  .member-item {
    border-bottom: 1px solid #f4f4f4;

    .left {
      padding: 16px 0;
      margin-left: 24px;

      .user-info-avatar {
        width: 44px;
        height: 44px;
      }
    }

    .name {
      margin-left: 10px;
      font-size: 14px;
      color: #000000;
    }

    .delete-button {
      height: 100%;
    }
  }

  ::v-deep .van-card {
    padding-bottom: 16px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 24px;
    margin-right: 15px;
    border-bottom: 1px solid #f4f4f4;
  }

  ::v-deep .van-col {
    font-size: 13px;
    font-weight: 500;
    color: #fe5800;
    text-align: center;
    line-height: 34px;
  }

  ::v-deep .van-row {
    padding-top: 12px;
  }
}
</style>
