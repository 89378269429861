<template>
  <div class="batchAdd_main">
    <batComent
      title="添加圈成员"
      placeholder="搜索成员进行添加"
      @input="input"
      @back="handleBack"
      @handleClick="handleClick"
      @vanSwipeClick="vanSwipeClick"
      @fileListAdd="fileListAdd"
      :vanSwipeList="vanSwipeList"
      :noDataInfo="noDataInfo"
      :fileList="fileList"
    ></batComent>
  </div>
</template>

<script>
import batComent from './batComent.vue'

export default {
  name: 'batchDel',
  components: {
    batComent,
  },
  data() {
    return {
      vanSwipeList: [
        {
          id: 1,
          title: '极氪员工-李三',
          isCheck: false,
          header: 'https://img01.yzcdn.cn/vant/cat.jpeg'
        }
      ],
      noDataInfo: {
        title1: '暂无相关人员'
      },
      fileList: []
    }
  },
  methods: {
    // 回调输入框change
    input(v) {
      console.log(v)
    },
    handleBack(){
      this.$emit('back')
    },
    // 回调输入框click
    handleClick(v) {
      console.log(v)
    },
    // 回调数据
    vanSwipeClick(item) {
      this.vanSwipeList.map((v) => {
        if (item.id === v.id) {
          v.isCheck = !v.isCheck;
        }
      })
    },
    // 回调添加
    fileListAdd() {

    }
  }
}
</script>

<style scoped>

</style>
